<template>
  <div class="loader-container">
    <b-spinner label="Spinning"></b-spinner>
  </div>
</template>

<script>
export default {
  name: "Loader"
}
</script>

<style scoped>

</style>