import { createI18n } from "vue-i18n";
import getBrowserLocale from "./util/i18n/getBrowserLocale";
import { supportedLocalesInclude } from "./util/i18n/supportedLocales";
import { nextTick } from "vue";

export const SUPPORT_LOCALES = ["de"];

export function setupI18n(options = { locale: "de" }) {
  const i18n = createI18n(options);
  setI18nLanguage(i18n, options.locale);
  return i18n;
}

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector("html").setAttribute("lang", locale);
}

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true });

  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale;
  } else {
    return process.env.VUE_APP_I18N_LOCALE || "de";
  }
}

export function loadLocaleMessages(i18n, locale) {
  // load locale messages with dynamic import
  const messages = import(
      /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
      );
  console.log(messages);

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default);

  return nextTick();
}
console.log(getStartingLocale());
// console.log(loadLocalMessages());
import de from "./locales/de.json";
import en from "./locales/en.json";


const i18n = createI18n({
  locale: getStartingLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  silentTranslationWarn: process.env.NODE_ENV === "production",
  messages: {
    en: en,
    de: de
  }
});

export default i18n;
