import { createStore } from "vuex";
import {auth} from "@/store/auth.module";
import {customer} from "@/store/customer.module";
import {cart} from "@/store/cart.module";
import {checkout} from "@/store/checkout.module";
import {loader} from "@/store/loading.module";
const store = createStore({
    modules: {
        auth,
        customer,
        cart,
        checkout,
        loader
    },
});
export default store;