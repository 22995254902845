<template>
  <div class="view-logged-out">
    {{$t('account.logged-out')}}
  </div>
</template>

<script>
export default {
  name: "Logout"
}
</script>

<style scoped>

</style>