import axios from 'axios';
import apiConfig from "@/config/apiConfig";
import authHeader from "@/services/auth-header";
const API_URL = apiConfig.getApiUrl();
import store from "@/store";
class CustomerService {
    getData() {
        return axios
            .get(API_URL + '/V1/customers/me', {headers: authHeader()})
            .then(response => {
                console.log(response);
                if (response.data) {
                    console.log("customer data");
                    console.log(response.data);
                    localStorage.setItem('customer', JSON.stringify(response.data));
                }
                return response.data;
            });
    }
    getBillingAddress() {
        return axios
            .get(API_URL + '/V1/customers/me/billingAddress', {headers: authHeader()})
            .then(response => {
                console.log("billing");
                console.log(response.data);
                return response.data;
            });
    }
    getShippingAddress() {
        return axios
            .get(API_URL + '/V1/customers/me/shippingAddress', {headers: authHeader()})
            .then(response => {
                console.log("shipping");
                console.log(response.data);
                return response.data;
            });
    }
    getCusomterAddress(addressId) {
        return axios
            .get(API_URL + '/V1/customers/addresses/'+addressId, {headers: authHeader()})
            .then(response => {
                console.log("customer address");
                console.log(response.data);
                return response.data;
            });
    }
    isCartAllowed() {
        return  store.state.customer.noCart;
    }
}
export default new CustomerService();