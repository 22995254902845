class Device {
    getOS(){
        console.log("OS:" + localStorage.getItem('osType'));
        return localStorage.getItem('osType')
    }
    setOS($os){
        localStorage.setItem('osType', $os);
    }
    getDisplayMode(){
        console.log("DisplayMode:" + localStorage.getItem('displayMode'));
        return localStorage.getItem('displayMode')
    }
    setDisplayMode($displayMode){
        localStorage.setItem('displayMode', $displayMode);
    }
    isPWA(){
        return this.getDisplayMode() === 'standalone';
    }
    isMobileAndPWA($var = "test"){
        console.log('is mobile pwa: '+$var);
        console.log((this.getOS()==='ios' || this.getOS()==='android') && !this.isPWA());
        return (this.getOS()==='ios' || this.getOS()==='android') && !this.isPWA()
    }
}
export default new Device();