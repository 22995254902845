import AuthService from '@/services/auth.service';
import store from "@/store/index";
import apiConfig from "@/config/apiConfig";
const user = JSON.parse(localStorage.getItem('user'));
const admin = JSON.parse(localStorage.getItem('admin'));
let initialState = null;
console.log("initial STate");
if(user && admin) {
    initialState = { status: { loggedIn: true, adminLoggedIn: true }, user, admin};
} else if (user) {
    initialState = { status: { loggedIn: true, adminLoggedIn: false }, user, admin:null};
} else if (admin) {
    initialState = { status: { loggedIn: false, adminLoggedIn: true }, user:null, admin};
} else {
    initialState = { status: { loggedIn: false, adminLoggedIn: false }, user: null, admin:null };
}
console.log(initialState);

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                user => {
                    console.log("login success");
                    console.log(user);
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    console.log("error!!");
                    console.log(error);
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
        adminLogin({ commit }, user) {
            console.log("adminLogin");
            console.log(user);
            return AuthService.adminLogin(user).then(
                user => {
                    console.log("login success");
                    commit('adminLoginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('adminLoginFailure');
                    return Promise.reject(error);
                }
            );
        },
        adminLogout({ commit }) {
            AuthService.adminLogout();
            commit('adminLogout');
        },
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
            store.dispatch("customer/getData")
            store.dispatch("auth/adminLogout");
            store.dispatch("auth/adminLogin",apiConfig.getApiUser())

        },
        adminLoginSuccess(state, user) {
            state.status.adminLoggedIn = true;
            state.admin = user;
            store.dispatch("checkout/setAgreements").then(
                () => {
                    store.dispatch("auth/adminLogout");
                }
            )

        },
        loginFailure(state) {
            state.admin = null;
            state.status.adminLoggedIn = false;
        },
        adminLoginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
            store.dispatch("auth/adminLogout");
        },
        adminLogout(state) {
            state.admin = null;
            state.status.adminLoggedIn = false;
        }
    }
};