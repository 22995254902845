<template>
  <header id="header">
    <div class="inside">
      <div class="inner-header">
        <div class="header-flex-fill header-left-container">
          <div class="header-account">
            <router-link to="/account" v-if="loggedIn">
              <font-awesome-icon icon="user" />
            </router-link>
            <div class="v-button v-primary v-login-button"  v-else>
              <router-link to="/login">
                {{$t('account.sign_in')}}
              </router-link>
            </div>

          </div>
        </div>
        <div class="header-logo">
          <router-link to="/">
            <img src="@/assets/img/CEMO_Logo.webp" alt="logo" />
          </router-link>
        </div>
        <div class="header-right-container">
          <LanguageSwitcher />
          <HeaderCart  :rerender="rerender"></HeaderCart>
        </div>
      </div>

    </div>
  </header>
</template>

<script>
import LanguageSwitcher from "@/components/partials/LanguageSwitcher";
import HeaderCart from "@/components/partials/account/HeaderCart";
export default {
  name: "Header",
  components: {HeaderCart, LanguageSwitcher},
  data() {
    return {
      url: false,
      rerender: 0,
    }
  },
  created() {
    this.url = window.location.pathname
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    cartAllowed() {
      return !this.$store.state.customer.noCart;
    },
  },
  mounted() {
    this.emitter.on('changedCart', () => {
      console.log("change Cart in Header");
      this.rerender++;
    })
  }
}
</script>

<style scoped>

</style>