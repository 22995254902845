<template>
  <footer id="footer">
    <div class="inside">
      <div class="inner-footer">
        <div class="footer-buttons">
          <!--        <div class="v-button v-primary">-->
          <!--          <a :href="'tel:'+$t('config.phone')">-->
          <!--            {{$t("Call us")}}-->
          <!--          </a>-->
          <!--        </div>-->
          <div class="v-button v-primary" v-if="show_info_button">
            <router-link class="" to="/add-to-home-screen"
            >
              {{$t("Info")}}
            </router-link>
          </div>
          <div class="v-button v-primary">
            <a :href="'mailto:'+$t('config.mail')">
              {{$t("E-Mail")}}
            </a>
          </div>
        </div>
        <div class="footer-icons">
          <a :href="$t('config.linkedin')">
            <img src="@/assets/img/SoMe_LinkedIn_256x256.png" alt="linkedin-icon" />
          </a>
          <a :href="$t('config.youtube')">
            <img src="@/assets/img/SoMe_YouTube_256x256.png" alt="youtube-icon" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import DeviceService from "@/services/device.service";

export default {
  name: "Footer",

  data() {
    return {
      show_info_button: false
    }
  },
  created() {
    this.showAddToHomeBanner();
  },
  mounted() {
    this.showAddToHomeBanner();
  },
  methods: {
    showAddToHomeBanner(){
      this.show_info_button =  DeviceService.isMobileAndPWA('footer');
    }
  }
}
</script>

<style scoped>

</style>
