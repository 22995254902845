<template>
  <div id="language-switcher">
    <b-dropdown class="language-dropdown">
      <template #button-content>
        <font-awesome-icon icon="globe"></font-awesome-icon> {{$filters.strToUppercase(this.$i18n.locale)}}
      </template>
      <b-dropdown-item v-for="(locale,key) in locales" :key="locale" @click="switchLocale(key)" >{{$t(locale)}} ({{ $filters.strToUppercase(key) }})</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import supportedLocales from "@/config/supportedLocales";
export default {
  name: "LanguageSwitcher",
  data() {
    return {
      locales: supportedLocales
    }
  },
  created() {
    console.log(this.locales);
  },
  methods: {
    switchLocale(locale) {
      console.log("switch locale");
      console.log(this.$i18n.locale);
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
      console.log(this.$i18n.locale);
    }
  }
}
</script>
