export default {
  "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "German": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
  "French": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
  "Italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
  "Español": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
  "Swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
  "config": {
    "store_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["store_en"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1234567"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["info", "@", "cemo-shop.de"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://facebook.com"])},
    "linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://linkedin.com"])},
    "youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://youtube.com"])}
  },
  "Call us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call us"])},
  "E-Mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "Pricefinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricefinder"])},
  "SKU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
  "home": {
    "home-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit"])},
    "type-sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type SKU"])},
    "start-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Search"])}
  },
  "search-result": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Result for:"])}
  },
  "Base Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base Data"])}
}