import store from "@/store/index";
import CartService from "@/services/cart.service";
const checkoutobj = JSON.parse(localStorage.getItem('checkout'));
const initialState = checkoutobj ? {checkoutobj} : {checkoutobj: {shippingAddress:null, billingAddress:null, shippingMethod:null, PaymentMethod:null, agreements: null}};
export const checkout = {
    namespaced: true,
    state: initialState,
    actions: {
        setShippingAddress({ commit }, addressId) {
            let addresses = store.state.customer.customerobj.addresses
            let address = addresses.find(address => address.id === addressId)
            delete address.region;
            delete address.region_id;
            delete address.default_billing;
            delete address.default_shipping;
            commit('setShippingAddressSuccess', address);
        },
        setShippingMethod({ commit }, shippingMethod) {
            console.log("setShippingMethod");
            console.log(shippingMethod);
            commit('setShippingMethodSuccess', shippingMethod);
        },
        setBillingAddress({ commit }, addressId) {
            let addresses = store.state.customer.customerobj.addresses
            let address = addresses.find(address => address.id === addressId)
            delete address.region;
            delete address.region_id;
            delete address.default_billing;
            delete address.default_shipping;
            commit('setBillingAddressSuccess', address);
        },
        setPaymentMethod({ commit }, paymentMethod) {
            console.log("setShippingMethod");
            console.log(paymentMethod);
            let method = {method: paymentMethod.code};
            CartService.setPaymentMethod(method);
            commit('setPaymentMethodSuccess', paymentMethod);
        },
        setAgreements({ commit }) {
            return CartService.getAgreements().then(
                agreements => {
                    commit('setAgreements', agreements);
                    return Promise.resolve(agreements);
                },
                error => {
                    commit('cartDataFailure');
                    return Promise.reject(error);
                }
            );
        },
    },
    mutations: {
        setShippingAddressSuccess(state, address) {
            state.checkoutobj.shippingAddress = address;
            localStorage.setItem('checkout', JSON.stringify(state.checkoutobj));
        },
        setBillingAddressSuccess(state, address) {
            state.checkoutobj.billingAddress = address;
            localStorage.setItem('checkout', JSON.stringify(state.checkoutobj));
        },
        setShippingMethodSuccess(state, shippingMethod) {
            state.checkoutobj.shippingMethod = shippingMethod;
            localStorage.setItem('checkout', JSON.stringify(state.checkoutobj));
        },
        setPaymentMethodSuccess(state, paymentMethod) {
            state.checkoutobj.paymentMethod = paymentMethod;
            localStorage.setItem('checkout', JSON.stringify(state.checkoutobj));
        },
        setAgreements(state,agreements) {
            state.checkoutobj.agreements = agreements;
            localStorage.setItem('checkout', JSON.stringify(state.checkoutobj));
        },
        cartDataFailure() {
        },
    }
};