import CustomerService from "@/services/customer.service";
import router from "@/router";
import store from "@/store/index";
const customerobj = JSON.parse(localStorage.getItem('customer'));
const initialState = {customerobj,noCart:false};
export const customer = {
    namespaced: true,
    state: initialState,
    actions: {
        getData({ commit }) {
            return CustomerService.getData().then(
                customer => {
                    console.log("customer module get data")
                    console.log(customer);
                    commit('setCustomerData', customer);
                    return Promise.resolve(customer);
                },
                error => {
                    commit('customerDataFailure');
                    return Promise.reject(error);
                }
            ).then(
                () => {
                    let billingAdr = false;
                    let shippingAdr = false;
                    CustomerService.getBillingAddress().then(
                        billing => {
                            billingAdr = Object.keys(billing).length;
                            console.log("billing!!!!");
                            console.log(billingAdr);
                        }
                    ).then(
                        () => {
                            CustomerService.getShippingAddress().then(
                                shipping => {
                                    shippingAdr =  Object.keys(shipping).length;
                                    console.log("shipping!!!!");
                                    console.log(shippingAdr);
                                    if(!shippingAdr || !billingAdr) {
                                        commit('setCustomerNoCart');
                                    } else {
                                        commit('setCustomerCart');
                                    }
                                }
                            );
                        }
                    );

                }
            );
        }
    },
    mutations: {
        setCustomerData(state, customer) {
            console.log("set customer data");
            state.customerobj = customer;
        },
        setCustomerNoCart(state) {
            state.noCart = true
            console.log("customer no cart allowed")
        },
        setCustomerCart(state) {
            state.noCart = false
            console.log("customer cart allowed")
        },
        customerDataFailure() {
            store.dispatch("auth/logout")
            router.push("/logged-out")
        },
    }
};