<template>
  <div id="result-header">
    <h1>{{$t('search-result.headline')}}</h1>
    <div class="result-header-sku">
      {{$t("SKU")}}: {{this.sku}}
    </div>
    <div class="v-button v-primary result-header-back-button">
      <a @click="this.$router.go(-1)">
        {{$t("back")}}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResultHeader",
  props: ["sku"]
}
</script>
