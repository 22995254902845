import {createWebHashHistory, createRouter } from 'vue-router';
import Home from '@/views/Home.vue';
import SearchResults from "@/views/SearchResults";
import ProductDetail from "@/views/ProductDetail";
import AddToHomeScreen from "@/views/AddToHomeScreen";
import Login from "@/views/Login";
import Account from "@/views/Account/Account";
import LoggedOut from "@/views/Account/LoggedOut";
import Cart from "@/views/Checkout/Cart";
import Checkout from "@/views/Checkout/Checkout";
import OrderSuccess from "@/views/Checkout/OrderSuccess";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/home',
        name: 'Home-home',
        component: Home,
    },
    {
        path: '/search-results/?sku=:sku?',
        name: 'SearchResults',
        component: SearchResults,
        props: true,
    },
    {
        path: '/product-detail/:sku?',
        name: 'ProductDetail',
        component: ProductDetail,
        props: true,
    },
    {
        path: '/add-to-home-screen',
        name: 'AddToHomeScreen',
        component: AddToHomeScreen,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/account',
        name: 'account',
        component: Account,
    },
    {
        path: '/logged-out',
        name: 'logged-out',
        component: LoggedOut,
    },
    {
        path: '/cart',
        name: 'cart',
        component: Cart,
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: Checkout,
    },
    {
        path: '/orderSuccess/:order',
        name: 'orderSuccess',
        component: OrderSuccess,
        props: true,
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
    },
});

export default router;