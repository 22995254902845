<template>
  <div >
    <router-link class="add-to-home-screen-banner"
        to="/add-to-home-screen"
    >
      <div class="athsb-text" v-html="$t('add_to_home_screen.banner_text')">
      </div>
      <div class="athsb-icon">
        <font-awesome-icon icon="plus-circle"></font-awesome-icon>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "AddToHomeScreen"
}
</script>

