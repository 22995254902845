import axios from 'axios';
import apiConfig from "@/config/apiConfig";
const API_URL = apiConfig.getApiUrl();
const headers = {
        'Content-Type': 'application/json',
};
class AuthService {
    login(user) {
        return axios
            .post(API_URL + '/V1/integration/customer/token', {
                username: user.username,
                password: user.password
            },{headers: headers})
            .then(response => {
                console.log("RESPONSE!!");
                console.log(response);
                if (response.data) {
                    console.log("token");
                    console.log(response.data);
                    localStorage.setItem('user', JSON.stringify(response.data));
                }
                return response.data;
            },
                error => {
                console.log("REPSONSE ERROR");
                console.log(error.toJSON());
                console.log(error.data);
                console.log(error.message);
                    return Promise.reject(error);
                })
    }
    logout() {
        console.log("logout");
        localStorage.removeItem('user');
        localStorage.removeItem('customer');
        localStorage.removeItem('cart');
    }
    adminLogin(user) {
        return axios
            .post(API_URL + 'V1/integration/admin/token', {
                username: user.username,
                password: user.password
            },{headers: headers})
            .then(response => {
                console.log("adminLogin in service");
                console.log(response);
                if (response.data) {
                    console.log("token");
                    console.log(response.data);
                    localStorage.setItem('admin', JSON.stringify(response.data));
                }
                return response.data;
            });
    }
    adminLogout() {
        console.log("logout");
        localStorage.removeItem('admin');
    }
}
export default new AuthService();