<template>
 <div class="view-order-success">
   <div class="view-container">
     <b-alert variant="success" show>{{$t("checkout.orderSuccess",{orderNumber:this.order})}}</b-alert>
   </div>
   <div class="v-button v-primary v-centered">
     <router-link to="/">
       {{$t("backToHome")}}
     </router-link>
   </div>
 </div>
</template>

<script>
import {BAlert} from "bootstrap-vue-3";
export default {
  name: "OrderSuccess",
  components: {BAlert},
  props:["order"],
    created() {
        this.$store.dispatch('cart/unsetData');
        this.emitter.emit('changedCart', {'changedCart': true});
    }
}
</script>

<style scoped>

</style>