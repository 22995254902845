<template>
  <div class="add-to-home-screen" :class="'phone-'+this.os">
    <div class="aths-headline">
      <h1>{{ $t("add_to_home_screen.page_headline") }}</h1>
    </div>
    <div class="aths-sub_text">
      {{ $t("add_to_home_screen.sub_text") }}<br>
      <strong>{{$t("add_to_home_screen."+os+".add_text")}}</strong>
    </div>
    <div class="aths-step-images">
<!--      <div class="aths-steps" :class="'active-step-'+step">-->
<!--        <div class="aths-step step-1" @click="this.step=1">-->
<!--          01-->
<!--        </div>-->
<!--        <div class="step-line"></div>-->
<!--        <div class="aths-step step-2" @click="this.step=2">-->
<!--          02-->
<!--        </div>-->
<!--        <div class="step-line"></div>-->
<!--        <div class="aths-step step-3" @click="this.step=3">-->
<!--          03-->
<!--        </div>-->
<!--        <div v-if="os==='android'"  class="step-line"></div>-->
<!--        <div v-if="os==='android'" class="aths-step step-4" @click="this.step=4">-->
<!--          04-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="aths-image step-img-1" :class="step===1 ? 'step-img-active' : ''" v-if="step===1" @click="nextStep()">-->
<!--        <img :src="require(`../assets/img/explanation/${language}/${os}/step_1.png`)" alt="step_image"/>-->
<!--      </div>-->
<!--      <div class="aths-image step-img-2" :class="step===2 ? 'step-img-active' : ''" @click="nextStep()">-->
<!--        <img :src="require(`../assets/img/explanation/${language}/${os}/step_2.png`)" alt="step_image"/>-->
<!--      </div>-->
<!--      <div class="aths-image step-img-3" :class="step===3 ? 'step-img-active' : ''" @click="nextStep()">-->
<!--        <img :src="require(`../assets/img/explanation/${language}/${os}/step_3.png`)" alt="step_image"/>-->
<!--      </div>-->
<!--      <div v-if="os==='android'" class="aths-image step-img-3" :class="step===4 ? 'step-img-active' : ''" @click="nextStep()">-->
<!--        <img :src="require(`../assets/img/explanation/${language}/${os}/step_4.png`)" alt="step_image"/>-->
<!--      </div>-->

      <swiper
          :pagination="pagination"
          :slides-per-view="1"
          :modules="modules"
          :space-between="50"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
      >
        <swiper-slide>
          <div class="aths-image step-img-1" :class="step===1 ? 'step-img-active' : ''">
            <img :src="require(`../assets/img/explanation/${language}/${os}/step_1.png`)" alt="step_image"/>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="aths-image step-img-2" :class="step===2 ? 'step-img-active' : ''">
            <img :src="require(`../assets/img/explanation/${language}/${os}/step_2.png`)" alt="step_image"/>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="aths-image step-img-3" :class="step===3 ? 'step-img-active' : ''">
            <img :src="require(`../assets/img/explanation/${language}/${os}/step_3.png`)" alt="step_image"/>
          </div>
        </swiper-slide>
        <swiper-slide v-if="os==='android'">
          <div class="aths-image step-img-1" :class="step===4 ? 'step-img-active' : ''">
            <img :src="require(`../assets/img/explanation/${language}/${os}/step_4.png`)" alt="step_image"/>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import getBrowserLocale from "@/util/i18n/getBrowserLocale";
import {supportedLocalesInclude} from "@/util/i18n/supportedLocales";
import DeviceService from "@/services/device.service";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
export default {
  name: "AddToHomeScreen",
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  data() {
    return {
      step: 1,
      language: "de",
      os: "ios",
      pagination:{
        clickable: true,
        /*Return bullets as numbers*/
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + "</span>";
        },
      }
    }
  },
  created() {
    this.os = DeviceService.getOS();
    this.language = this.getStartingLocale();
  },
  methods: {
    nextStep() {
      if(this.os === 'android'){
        if (this.step < 4) {
          this.step++;
        }
      }else {
        if (this.step < 3) {
          this.step++;
        }
      }

    },
    getStartingLocale() {
      const browserLocale = getBrowserLocale({countryCodeOnly: true});

      if (supportedLocalesInclude(browserLocale)) {
        return browserLocale;
      } else {
        return process.env.VUE_APP_I18N_LOCALE || "en";
      }
    }
  }
}
</script>

<style scoped>

</style>