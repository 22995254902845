<template>
  <div class="view-account">
    <div class="view-container" >
      <div class="v-a-data" v-if="customer">
        <div class="single-account-data">
          <div class="sad-label">
            {{$t('account.mail')}}
          </div>
          <div class="sad-value">
            {{customer.email}}
          </div>
        </div>
        <div class="single-account-data">
          <div class="sad-label">
            {{$t('account.firstname')}}
          </div>
          <div class="sad-value">
            {{customer.firstname}}
          </div>
        </div>
        <div class="single-account-data">
          <div class="sad-label">
            {{$t('account.lastname')}}
          </div>
          <div class="sad-value">
            {{customer.lastname}}
          </div>
        </div>

      </div>
      <div class="account-address-container" v-if="cartAllowed">
        <div class="account-billing-container account-single-address">
          <h3>{{$t('account.billingAddress')}}</h3>
          <AddressDisplay :address="billing"></AddressDisplay>
        </div>
        <div class="account-shipping-container account-single-address">
          <h3>{{$t('account.shippingAddress')}}</h3>
          <AddressDisplay :address="shipping"></AddressDisplay>
        </div>
      </div>
      <div v-else style="margin-bottom:30px; color:red;">
        {{$t("account.noAddressesText")}}
      </div>
      <div class="v-button v-primary v-centered" style="margin-bottom:20px;">
        <router-link to="/">
          {{$t('account.priceFinderButton')}}
        </router-link>
      </div>
      <div class="v-button v-primary v-centered">
        <a href="logout"  @click.prevent="logOut" >
          {{$t('account.logout')}}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerService from "@/services/customer.service";
import AddressDisplay from "@/components/partials/account/AddressDisplay";

export default {
  name: "Account",
  components: {AddressDisplay},
  data() {
    return {
      customer: this.$store.state.customer.customerobj,
      billing: {},
      shipping: {}
    }
  },
  computed: {
    cartAllowed() {
      return !this.$store.state.customer.noCart;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/logged-out');
    },
    getCustomerData() {
      this.customer = JSON.parse(localStorage.getItem('customer'));
      CustomerService.getData().then(
          customer => {
            this.customer = customer;
          }
      );
      CustomerService.getBillingAddress().then(
          billing => {
            this.billing = billing;
          }
      );
       CustomerService.getShippingAddress().then(
           shipping => {
            this.shipping = shipping;
          }
      );
      console.log(this.$store.state);
    }
  },
  mounted() {
    this.getCustomerData();

  }
}
</script>