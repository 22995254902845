<template>
  <div id="cemo-contact">
    <div class="contact-address">
      <div class="contact-company">
        {{$t("contact.company")}}
      </div>
      <div class="contact-street">
        {{$t("contact.street")}}
      </div>
      <div class="contact-city">
        {{$t("contact.postcode")}}  {{$t("contact.city")}} /  {{$t("contact.country")}}
      </div>
    </div>
    <div class="contact-links">
      <a :href="$t('contact.imprint_url')">
        {{$t('contact.imprint')}}
      </a>
      <a :href="$t('contact.privacy_url')">
        {{$t('contact.privacy')}}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "CemoContact"
}
</script>
