<template>
    <div class="view-checkout-shipping">
        <div class="view-container">
            <div class="checkout-steps">
                <div class="single-checkout-step shipping-step-step" :class="{active: this.step==='shipping'}"
                     @click="toShippingStep">
                    {{ $t("checkout.shipping") }}
                </div>
                <div class="single-checkout-step shipping-step-step" v-if="billingActive"
                     :class="{active: this.step==='billing'}" @click="toBillingStep">
                    {{ $t("checkout.billing") }}
                </div>
            </div>
            <div class="checkout-shipping-step" v-if="this.step==='shipping'">
                <h3>{{ $t('checkout.shipping_address') }}</h3>
                <div v-if="loggedIn" class="checkout-shipping-addresses">
                    <AddressDisplay v-on:chooseAddress="chooseAddress" v-for="address in this.customer.addresses"
                                    checkout="true"
                                    :active="address.id === this.selectedShippingAddress.id" :address="address"
                                    :key="address.id"></AddressDisplay>
                </div>
                <div v-else class="checkout-add-shipping-address">
                    <div class="checkout-shipping-address-set" v-if="shippingAddressSet">
                        <AddressDisplay :active="true" :address="shippingAddressObj" />
                        <div class="v-button v-primary v-centered">
                            <a href="#billing" @click.prevent="editShippingAddress">
                                {{ $t('edit') }}
                            </a>
                        </div>
                    </div>
                    <Form v-else @submit="handleSubmitShippingAddress" :validation-schema="this.shipping_address_schema">
                        <div class="form-group">
                            <label for="shipping_mail">
                                {{ $t("address.mail") }}*
                            </label>
                            <Field v-model="shippingAddressObj.email" id="shipping_email" name="shipping_email" type="shipping_email"
                                   class="form-control"/>
                            <ErrorMessage name="shipping_email" class="error-feedback"/>
                        </div>
                        <div class="form-group">
                            <label for="shipping_firstname">
                                {{ $t("address.firstname") }}*
                            </label>
                            <Field v-model="shippingAddressObj.firstname" id="shipping_firstname" name="shipping_firstname"
                                   type="text" class="form-control"/>
                            <ErrorMessage name="shipping_firstname" class="error-feedback"/>
                        </div>
                        <div class="form-group">
                            <label for="shipping_lastname">
                                {{ $t("address.lastname") }}*
                            </label>
                            <Field v-model="shippingAddressObj.lastname" id="shipping_lastname" name="shipping_lastname"
                                   type="text" class="form-control"/>
                            <ErrorMessage name="shipping_lastname" class="error-feedback"/>
                        </div>
                        <div class="form-group">
                            <label for="shipping_company">
                                {{ $t("address.company") }}*
                            </label>
                            <Field v-model="shippingAddressObj.company" id="shipping_company" name="shipping_company"
                                   type="text" class="form-control"/>
                            <ErrorMessage name="shipping_company" class="error-feedback"/>
                        </div>
                        <div class="form-group">
                            <label for="shipping_street">
                                {{ $t("address.street") }}*
                            </label>
                            <Field v-model="shippingAddressObj.street[0]" id="shipping_street" name="shipping_street"
                                   type="text" class="form-control"/>
                            <ErrorMessage name="shipping_street" class="error-feedback"/>
                        </div>
                        <div class="form-group">
                            <label for="shipping_city">
                                {{ $t("address.city") }}*
                            </label>
                            <Field v-model="shippingAddressObj.city" id="shipping_city" name="shipping_city"
                                   type="text" class="form-control"/>
                            <ErrorMessage name="shipping_city" class="error-feedback"/>
                        </div>
                        <div class="form-group">
                            <label for="shipping_postcode">
                                {{ $t("address.postcode") }}*
                            </label>
                            <Field v-model="shippingAddressObj.postcode" id="shipping_postcode" name="shipping_postcode"
                                   type="text" class="form-control"/>
                            <ErrorMessage name="shipping_postcode" class="error-feedback"/>
                        </div>
                        <div class="form-group">
                            <label for="shipping_country">
                                {{ $t("address.country") }}*
                            </label>
                            <Field v-model="shippingAddressObj.country_id" id="shipping_country" name="shipping_country"
                                   as="select" class="form-control">
                                  <option v-for="country in this.countries" :value="country.id" :key="country.id">{{country.full_name_locale}}</option>
                            </Field>
                            <ErrorMessage name="shipping_country" class="error-feedback"/>
                        </div>
                        <div class="form-group">
                            <label for="shipping_telephone">
                                {{ $t("address.telephone") }}*
                            </label>
                            <Field v-model="shippingAddressObj.telephone" id="shipping_telephone" name="shipping_telephone"
                                   type="phone" class="form-control"/>
                            <ErrorMessage name="shipping_telephone" class="error-feedback"/>
                        </div>
                        <div class="v-button v-primary v-centered v-button-shipping-address">
                            <button   type="submit">
                                {{ $t('checkout.continue') }}
                            </button>
                        </div>
                    </Form>

                </div>
                <div class="shipping-methods-container" v-if="this.shippingMethods">
                    <h3>{{ $t('checkout.shipping_methods') }}</h3>
                    <Form @submit="handleSubmitShipping" :validation-schema="this.shipping_schema">
                        <div class="checkout-shipping-methods" >
                            <div
                                    v-for="method in this.shippingMethods"
                                    :key="method.method_code"
                                    data-testid="shipping-method"
                            >
                                <Field
                                        v-model="selectedShippingMethod"
                                        :value="method"
                                        :id="method.method_code"
                                        type="radio"
                                        name="shippingMethod"
                                        @change="setSelectedShippingMethod()"
                                />
                                <label :for="method.method_code">
              <span class="label__text">
                {{ method.carrier_title }} - {{ method.method_title }}
              </span>
                                    <span class="label__price">
            {{ $filters.toCurrency(method.price_incl_tax) }}
          </span>
                                </label>
                            </div>
                            <ErrorMessage name="shipping_shippingMethod" class="error-feedback"/>
                        </div>
                        <div class="v-button v-primary v-centered">
                            <button href="#billing" type="submit">
                                {{ $t('checkout.continue_billing') }}
                            </button>
                        </div>
                    </Form>
                </div>


            </div>
            <div class="checkout-billing-step" v-if="this.step==='billing'">

                <div v-if="loggedIn" class="checkout-billing-address">
                    <AddressDisplay :address="this.billingAddress" :active="true"></AddressDisplay>
                </div>
                <div v-else class="checkout-billing-guest">
                    <div class="checkout-billing-guest-address" v-if="billingAddressSet">
                        <AddressDisplay :address="this.billingAddress" :active="true"></AddressDisplay>
                        <div class="v-button v-primary v-centered">
                            <a href="#billing" @click.prevent="editBillingAddress">
                                {{ $t('edit') }}
                            </a>
                        </div>
                    </div>
                    <div v-else>
                        <Form  @submit="handleSubmitBillingAddress" :validation-schema="this.billing_address_schema">
                            <div class="form-group">
                                <label for="billing_firstname">
                                    {{ $t("address.firstname") }}*
                                </label>
                                <Field v-model="billingAddressObj.firstname" id="billing_firstname" name="billing_firstname"
                                       type="text" class="form-control"/>
                                <ErrorMessage name="billing_firstname" class="error-feedback"/>
                            </div>
                            <div class="form-group">
                                <label for="billing_lastname">
                                    {{ $t("address.lastname") }}*
                                </label>
                                <Field v-model="billingAddressObj.lastname" id="billing_lastname" name="billing_lastname"
                                       type="text" class="form-control"/>
                                <ErrorMessage name="billing_lastname" class="error-feedback"/>
                            </div>
                            <div class="form-group">
                                <label for="billing_company">
                                    {{ $t("address.company") }}*
                                </label>
                                <Field v-model="billingAddressObj.company" id="billing_company" name="billing_company"
                                       type="text" class="form-control"/>
                                <ErrorMessage name="billing_company" class="error-feedback"/>
                            </div>
                            <div class="form-group">
                                <label for="billing_street">
                                    {{ $t("address.street") }}*
                                </label>
                                <Field v-model="billingAddressObj.street[0]" id="billing_street" name="billing_street"
                                       type="text" class="form-control"/>
                                <ErrorMessage name="billing_street" class="error-feedback"/>
                            </div>
                            <div class="form-group">
                                <label for="billing_city">
                                    {{ $t("address.city") }}*
                                </label>
                                <Field v-model="billingAddressObj.city" id="billing_city" name="billing_city"
                                       type="text" class="form-control"/>
                                <ErrorMessage name="billing_city" class="error-feedback"/>
                            </div>
                            <div class="form-group">
                                <label for="billing_postcode">
                                    {{ $t("address.postcode") }}*
                                </label>
                                <Field v-model="billingAddressObj.postcode" id="billing_postcode" name="billing_postcode"
                                       type="text" class="form-control"/>
                                <ErrorMessage name="billing_postcode" class="error-feedback"/>
                            </div>
                            <div class="form-group">
                                <label for="billing_country">
                                    {{ $t("address.country") }}*
                                </label>
                                <Field v-model="billingAddressObj.country_id" id="billing_country" name="billing_country"
                                       as="select" class="form-control">
                                    <option v-for="country in this.countries" :value="country.id" :key="country.id">{{country.full_name_locale}}</option>
                                </Field>
                                <ErrorMessage name="billing_country" class="error-feedback"/>
                            </div>
                            <div class="form-group">
                                <label for="billing_telephone">
                                    {{ $t("address.telephone") }}*
                                </label>
                                <Field v-model="billingAddressObj.telephone" id="billing_telephone" name="billing_telephone"
                                       type="phone" class="form-control"/>
                                <ErrorMessage name="billing_telephone" class="error-feedback"/>
                            </div>
                            <div class="v-button v-primary v-centered v-button-shipping-address">
                                <button   type="submit">
                                    {{ $t('checkout.continue') }}
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
                <Form @submit="handleSubmit" :validation-schema="this.billing_schema">
                    <div class="checkout-payment-methods"
                         v-for="method in paymentMethods"
                         :key="method.id"
                    >
                        <Field
                                v-model="selectedPaymentMethod"
                                :value="method"
                                :id="method.code"
                                type="radio"
                                name="paymentMethod"
                                @change="setSelectedPaymentMethod"
                        />
                        <label :for="method.code">
                            {{ method.title }}
                        </label>

                    </div>
                    <ErrorMessage name="shipping_paymentMethod" class="error-feedback"/>


                    <div class="checkout-agreement-container">
                        <div class="single-agreement" v-for="agreement in agreements" :key="agreement.agreement_id">
                            <Field
                                    :value="agreement.agreement_id"
                                    type="checkbox"
                                    :id="'agreement_'+agreement.agreement_id"
                                    :name="'agreement_'+agreement.agreement_id"
                            />
                            <label :for="'agreement_'+agreement.agreement_id">
                                <a href="#" @click.prevent="openAgreementPopup(agreement.content)">
                                    {{ agreement.checkbox_text }}
                                </a>
                            </label>

                            <ErrorMessage :name="'agreement_'+agreement.agreement_id" class="error-feedback"/>
                        </div>
                        <div class="agreement-popup" v-if="show_agreement_popup">
                            <div class="agreement-popup-content" v-html="agreement_content"></div>
                            <a href="#" class="close-agreement-icon"
                               @click.prevent="this.show_agreement_popup=false">X</a>
                        </div>
                    </div>
                    <div class="form-group fg-button v-centered">
                        <button class="btn btn-primary btn-block" :disabled="loading" type="submit">
            <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
            ></span>
                            <span>Kostenpflichtig bestellen</span>
                        </button>
                    </div>
                </Form>
            </div>

            <div class="cart-totals-container">
                <CartTotal/>
            </div>
        </div>
    </div>
</template>

<script>

import AddressDisplay from "@/components/partials/account/AddressDisplay";
import CartService from "@/services/cart.service";
import CustomerService from "@/services/customer.service";
import CartTotal from "@/components/partials/cart/CartTotal";
import {Field, Form, ErrorMessage} from "vee-validate";
import * as yup from "yup";
import globalService from "@/services/global.service";
import {useToast} from "vue-toastification";
import apiConfig from "@/config/apiConfig";

export default {
    name: "Checkout",
    components: {
        CartTotal, AddressDisplay, Form, ErrorMessage,
        Field,
    },
    setup() {
        const toast = useToast();
        return {toast}
    },
    data() {
        const shipping_address_schema = yup.object().shape({
            shipping_email: yup.string().required(this.$i18n.t("checkout.shippingFieldRequired")),
            shipping_firstname: yup.string().required(this.$i18n.t("checkout.shippingFieldRequired")),
            shipping_lastname: yup.string().required(this.$i18n.t("checkout.shippingFieldRequired")),
            shipping_company: yup.string().required(this.$i18n.t("checkout.shippingFieldRequired")),
            shipping_street: yup.string().required(this.$i18n.t("checkout.shippingFieldRequired")),
            shipping_postcode: yup.string().required(this.$i18n.t("checkout.shippingFieldRequired")),
            shipping_city: yup.string().required(this.$i18n.t("checkout.shippingFieldRequired")),
            shipping_country: yup.string().required(this.$i18n.t("checkout.shippingFieldRequired")),
            shipping_telephone: yup.string().required(this.$i18n.t("checkout.shippingFieldRequired")),
        });
        const billing_address_schema = yup.object().shape({
            billing_firstname: yup.string().required(this.$i18n.t("checkout.shippingFieldRequired")),
            billing_lastname: yup.string().required(this.$i18n.t("checkout.shippingFieldRequired")),
            billing_company: yup.string().required(this.$i18n.t("checkout.shippingFieldRequired")),
            billing_street: yup.string().required(this.$i18n.t("checkout.shippingFieldRequired")),
            billing_postcode: yup.string().required(this.$i18n.t("checkout.shippingFieldRequired")),
            billing_city: yup.string().required(this.$i18n.t("checkout.shippingFieldRequired")),
            billing_country: yup.string().required(this.$i18n.t("checkout.shippingFieldRequired")),
            billing_telephone: yup.string().required(this.$i18n.t("checkout.shippingFieldRequired")),
        });
        const shipping_schema = yup.object().shape({
            shippingMethod: yup.object().required(this.$i18n.t("checkout.shippingRequired")).nullable(),
        });
        const billing_schema = yup.object().shape({
            paymentMethod: yup.object().required(this.$i18n.t("checkout.paymentRequired")),
            agreement_1: yup.string().required(this.$i18n.t("checkout.agreementRequired")),
            agreement_2: yup.string().required(this.$i18n.t("checkout.agreementRequired")),
            agreement_3: yup.string().required(this.$i18n.t("checkout.agreementRequired")),
        });
        return {
            step: "shipping",
            customer: JSON.parse(localStorage.getItem('customer')),
            cart: JSON.parse(localStorage.getItem('cart')),
            shippingAddressId: 0,
            shippingMethods: false,
            paymentMethods: {},
            selectedShippingMethod: null,
            selectedPaymentMethod: null,
            billingAddress: null,
            billingActive: false,
            billing_schema,
            shipping_schema,
            shipping_address_schema,
            billing_address_schema,
            agreement_content: "",
            shippingAddressSet: false,
            billingAddressSet: false,
            shippingAddressObj: {
                email: "",
                firstname: "",
                lastname: "",
                company: "",
                street: [""],
                city: "",
                postcode: "",
                region: "",
                region_code: "",
                region_id: "",
                telephone: "",
                same_as_billing: 0
            },
            billingAddressObj: {
                email: "",
                firstname: "",
                lastname: "",
                company: "",
                street: [""],
                city: "",
                postcode: "",
                region: "",
                region_code: "",
                region_id: "",
                telephone: "",
            },
            show_agreement_popup: false,
            countries: false
        }
    },
    computed: {
        selectedShippingAddress() {
            return this.$store.state.checkout.checkoutobj.shippingAddress;
        },
        selectedBillingAddress() {
            return this.$store.state.checkout.checkoutobj.billingAddress;
        },
        currentShippingMethod() {
            return this.$store.state.checkout.checkoutobj.shippingMethod;
        },
        currentPaymentMethod() {
            return this.$store.state.checkout.checkoutobj.paymentMethod;
        },
        agreements() {
            return this.$store.state.checkout.checkoutobj.agreements;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    created() {
        this.getCountries();
        this.getPaymentMethods();
        this.getCart();
        this.$store.dispatch("auth/adminLogin",apiConfig.getApiUser())
        if (this.loggedIn) {
            this.billingAddress = this.cart.billing_address;
            if (!this.addressValid(this.billingAddress)) {
                this.getStandardBillingAddress();
            }
            this.selectedShippingMethod = this.currentShippingMethod;
            this.selectedPaymentMethod = this.currentPaymentMethod;
            if (!this.selectedShippingAddress) {
                console.log("shipping address is NOT selected");
                CustomerService.getShippingAddress().then(
                    address => {
                        this.shippingAddressId = address.id;
                        delete address.region;
                        delete address.region_id;
                        delete address.default_billing;
                        delete address.default_shipping;
                        this.$store.dispatch("checkout/setShippingAddress", address.id);
                        this.getShippingMethods(address);
                    }
                )
            } else {
                console.log("shipping address is selected");
                console.log(this.selectedShippingAddress);
                this.shippingAddressId = this.selectedShippingAddress.id;
                this.getShippingMethods(this.selectedShippingAddress)
            }
            this.getShippingAddressId(this.shippingAddressObj);
        } else {
            this.selectedShippingMethod = this.currentShippingMethod;
            this.selectedPaymentMethod = this.currentPaymentMethod;
            CartService.getCart().then(
                cart => {
                    console.log("get cart checkout");
                    console.log(cart);
                    this.cart = cart;
                }
            )
                .then(
                    () => {
                        if(this.cart.billing_address){
                            let billingCart =this.cart.billing_address;
                            if(billingCart.city && billingCart.firstname && billingCart.lastname) {
                                this.billingAddressObj = this.cart.billing_address;
                                this.billingAddressSet = true;
                                this.billingAddress = this.billingAddressObj;
                            }
                        }
                        console.log("get shipping from guest cart");
                        console.log(this.cart.extension_attributes['shipping_assignments'][0].shipping);
                        if(this.cart.extension_attributes['shipping_assignments']){
                            if(this.cart.extension_attributes['shipping_assignments'][0]){
                                if(this.cart.extension_attributes['shipping_assignments'][0].shipping){
                                    console.log("shipping from cart");

                                    let addressCart = this.cart.extension_attributes['shipping_assignments'][0].shipping.address;
                                    console.log(addressCart);
                                    if(addressCart.city && addressCart.firstname && addressCart.lastname && addressCart.email){
                                        this.shippingAddressObj = this.cart.extension_attributes['shipping_assignments'][0].shipping.address;
                                        this.shippingAddressSet = true;
                                        this.getShippingMethods(this.shippingAddressObj);
                                    }
                                }
                            }
                        }
                    }
                )


        }
    },
    methods: {
        getCart(){
            CartService.getCart().then(
                cart => {
                    console.log("get cart checkout");
                    console.log(cart);
                    this.cart = cart;
                }
            )
        },
        editShippingAddress(){
            this.shippingAddressSet = false;
            this.shippingMethods = null;
        },
        editBillingAddress(){
            this.billingAddressSet = false;
        },
        handleSubmitBillingAddress() {
            globalService.getCountry(this.billingAddressObj.country_id).then(
                country => {
                    //console.log(country);
                    let region = country.available_regions[0];
                    this.billingAddressObj.region_id = region.id;
                    this.billingAddressObj.region = region.name;
                    this.billingAddressObj.region_code = region.code;
                }
            )
                .then(
                    () => {
                        delete this.billingAddressObj.id;
                        CartService.setBillingAddressFromCart(this.billingAddressObj).then(
                            response => {
                                console.log(response);
                                this.billingAddress = this.billingAddressObj;
                                this.billingAddressSet = true;
                                this.getCart();
                            }
                        );
                    }
                )

        },
        handleSubmitShippingAddress(){
            console.log("submit Shipping Address");
            globalService.getCountry(this.shippingAddressObj.country_id).then(
                country => {
                    //console.log(country);
                    let region = country.available_regions[0];
                    this.shippingAddressObj.region_id = region.id;
                    this.shippingAddressObj.region = region.name;
                    this.shippingAddressObj.region_code = region.code;
                    this.shippingAddressSet = true;
                    this.billingAddressSet = true;
                    this.getCart();
                }
            )
                .then(
                    () => {
                        this.getShippingMethods(this.shippingAddressObj)
                    }
                )

        },
        getCountries() {
            globalService.getCountries().then(
                response => {
                    console.log("get countries");
                    console.log(response);
                    this.countries=response;
                }
            )
        },
        openAgreementPopup(content) {
            this.agreement_content = content;
            this.show_agreement_popup = true;

        },
        handleSubmit() {
            console.log("submit");
            let billingAddress = JSON.parse(JSON.stringify(this.billingAddress));
            let paymentMethod = {
                method: this.selectedPaymentMethod.code,
                extension_attributes: {agreement_ids: ["1", "2", "3"]}
            }

            delete billingAddress.default_billing;
            delete billingAddress.default_shipping;
            delete billingAddress.customer_id;
            delete billingAddress.id;

            billingAddress.email = this.shippingAddressObj.email;

            //console.log(addressInformation);
            globalService.getCountry(billingAddress.country_id).then(
                country => {
                    //console.log(country);
                    let region = country.available_regions[0];
                    billingAddress.region_id = region.id;
                    billingAddress.region = region.name;
                    billingAddress.region_code = region.code;
                }
            ).then(
                () => {
                    console.log(paymentMethod);
                    CartService.setPaymentInformation(billingAddress, paymentMethod, this.shippingAddressObj.email).then(
                        data => {
                            this.toast.success(this.$i18n.t("checkout.checkoutSuccess"));
                            return data;
                        },
                        error => {
                            this.toast.error(error.message);
                        }
                    ).then(
                        (data) => {
                            this.$router.push({name: "orderSuccess", params: {order: data}})
                        });
                }
            )

        },
        handleSubmitShipping() {
            console.log("handle submit shipping");
            this.billingActive = true;
            this.toBillingStep();
        },
        getStandardBillingAddress() {
            CustomerService.getBillingAddress().then(
                billingAddress => {
                    this.billingAddress = billingAddress;
                }
            )
        },
        addressValid(address) {
            return address.firstname || address.lastname && address.street.length;
        },
        setSelectedShippingMethod() {
            this.$store.dispatch(
                'checkout/setShippingMethod',
                this.selectedShippingMethod
            )
        },
        setSelectedPaymentMethod() {
            this.$store.dispatch(
                'checkout/setPaymentMethod',
                this.selectedPaymentMethod
            ).then(
                () => {
                    CartService.getSelectedPaymentMethod().then(
                        paymentMethod => {
                            console.log(paymentMethod);
                        }
                    )
                }
            );
        },
        getShippingMethods(address) {
            CartService.estimateShippingMethods(address).then(
                shippingMethods => {
                    console.log("shipping Methods");

                    //console.log(shippingMethods);
                    this.shippingMethods = shippingMethods;
                    this.selectedShippingMethod = this.shippingMethods[0];
                    console.log(this.selectedShippingMethod);
                    this.setSelectedShippingMethod();
                    // let addressInformation = {
                    //   shipping_address: address,
                    //   shipping_carrier_code: shippingMethods[0].carrier_code,
                    //   shipping_method_code: shippingMethods[0].method_code
                    // };

                }
            )
        },
        chooseAddress(address) {
            //console.log("choose Address Shipping");
            //console.log(address);
            delete address.region;
            delete address.region_id;
            delete address.default_billing;
            delete address.default_shipping;
            this.getShippingMethods(address);
            this.$store.dispatch(
                'checkout/setShippingAddress',
                address.id
            );
            this.shippingAddressId = address.id;
        },
        getShippingAddressId() {
            if (CartService.isShippingSameAsBilling()) {
                console.log("shipping is same as billing");
                CustomerService.getBillingAddress().then(
                    billingAddress => {
                        //console.log("BILLING ADDRESS");
                        //console.log(billingAddress);
                        this.shippingAddressId = billingAddress.id
                    }
                )
            } else {
                console.log("shipping is NOT same as billing");
                console.log(CartService.getShippingAddressFromCart());
                this.shippingAddressId = CartService.getShippingAddressFromCart().id;
            }
        },
        toBillingStep() {
            this.step = "billing";
            //console.log("shipping Method");
            //console.log(this.selectedShippingMethod);
            let addressInformation = {}
            let shipping_address = {};
            let billing_address = {};
            if(this.loggedIn){
                shipping_address = JSON.parse(JSON.stringify(this.selectedShippingAddress));
                billing_address = JSON.parse(JSON.stringify(this.billingAddress));
                addressInformation = {
                    shipping_address: shipping_address,
                    billing_address: billing_address,
                    shipping_carrier_code: this.selectedShippingMethod.carrier_code,
                    shipping_method_code: this.selectedShippingMethod.method_code
                }
            } else {

                this.selectedShippingAddress = this.shippingAddressObj;
                if(!this.billingAddress){
                    this.billingAddress = this.shippingAddressObj;
                }
                shipping_address = JSON.parse(JSON.stringify(this.shippingAddressObj));
                billing_address = JSON.parse(JSON.stringify(this.billingAddress));
                this.billingAddress = billing_address;
                addressInformation = {
                    shipping_address: shipping_address,
                    billing_address: billing_address,
                    shipping_carrier_code: this.selectedShippingMethod.carrier_code,
                    shipping_method_code: this.selectedShippingMethod.method_code
                }
            }
            delete addressInformation.shipping_address.default_billing;
            delete addressInformation.shipping_address.default_shipping;
            delete addressInformation.shipping_address.id;


            delete addressInformation.billing_address.default_billing;
            delete addressInformation.billing_address.default_shipping;
            delete addressInformation.billing_address.id;

            //console.log(addressInformation);
            globalService.getCountry(shipping_address.country_id).then(
                country => {
                    //console.log(country);
                    let region = country.available_regions[0];
                    addressInformation.shipping_address.region_id = region.id;
                    addressInformation.shipping_address.region = region.name;
                    addressInformation.shipping_address.region_code = region.code;
                }
            ).then(
                () => {
                    globalService.getCountry(billing_address.country_id).then(
                        country => {
                            //console.log(country);
                            let region = country.available_regions[0];
                            addressInformation.billing_address.region_id = region.id;
                            addressInformation.billing_address.region = region.name;
                            addressInformation.billing_address.region_code = region.code;
                            //console.log("region");
                            //console.log(region);
                            //console.log(addressInformation);
                        }
                    ).then(
                        () => {
                            //console.log("addressInformation");
                            //console.log(addressInformation);
                            CartService.setShippingInformation(addressInformation).then(
                                ()=>{
                                    this.emitter.emit('changedCart', {'changedCart': true});
                                }
                            );

                        }
                    )
                }
            )

        },
        toShippingStep() {
            this.step = "shipping";
        },
        getPaymentMethods() {
            CartService.getPaymentMethods().then(
                paymentMethods => {
                    console.log("GET PAYMENTS");
                    console.log(paymentMethods);
                    this.paymentMethods = paymentMethods;
                }
            )
        }
    }
}
</script>