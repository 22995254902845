import {createApp} from 'vue'
import App from './App.vue'

import router from './router' // <---
import i18n from "@/i18n";

import BootstrapVue3 from 'bootstrap-vue-3';
import {useToast} from "vue-toastification";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';

import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

import { v4 as uuidv4 } from "uuid";

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {faArrowRotateLeft} from "@fortawesome/free-solid-svg-icons";
import {faCheck} from "@fortawesome/free-solid-svg-icons";



library.add(faFacebook);
library.add(faTwitter);
library.add(faLinkedin);
library.add(faLinkedinIn);
library.add(faYoutube);
library.add(faChevronDown);
library.add(faChevronUp);
library.add(faGlobe);
library.add(faPlusCircle);
library.add(faUser);
library.add(faShoppingCart);
library.add(faTrash);
library.add(faArrowRotateLeft);
library.add(faCheck);



import "./assets/less/main.less";

import axios from 'axios'
import VueAxios from 'vue-axios'
import store from "./store";
import './registerServiceWorker'

import mitt from 'mitt';
import ProductService from "@/services/product.service";
const emitter = mitt();
const app = createApp(App).use(i18n);

axios.interceptors.response.use(function (response) {
    console.log(response);
    return response;
}, function (error) {
    console.log("error");
    console.log(error);
    console.log(error.toJSON());
    store.dispatch("loader/unSetLoader");
    if (401 === error.response.status) {
        const toast = useToast();
        console.log("loggedin:"+store.state.auth.status.loggedIn);
        if(store.state.auth.status.loggedIn) {
            toast.error(i18n.global.t("account.loginError"));
            store.dispatch("auth/logout");
        }
        return Promise.reject(error);
    } else {
        return Promise.reject(error);
    }
});

axios.interceptors.request.use((config) => {
    store.dispatch("loader/setLoader");
    return config;
}, (error) => {
    // trigger 'loading=false' event here
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    store.dispatch("loader/unSetLoader");
    return response;
}, (error) => {
    // trigger 'loading=false' event here
    return Promise.reject(error);
});

app.use(router)
app.use(i18n)
app.use(VueAxios, axios)
app.use(store)
app.use(BootstrapVue3)
app.use(uuidv4)
app.use(Toast, {
    // Setting the global default position
    position: POSITION.BOTTOM_RIGHT
});

app.component("font-awesome-icon", FontAwesomeIcon)



//Filters
app.config.globalProperties.$filters = {
    strToUppercase(str) {
        return str.toUpperCase();
    },
    toCurrency(str){
        return ProductService.toCurrency(str);
    }
};
app.config.globalProperties.emitter = emitter;



app.mount('#app')
