import CartService from "@/services/cart.service";
const cartobj = JSON.parse(localStorage.getItem('cart'));
let guestCartId = JSON.parse(localStorage.getItem('guestCartId'));
if(!guestCartId){
    guestCartId = null;
}
const initialState = {cart:cartobj,guestCartId:guestCartId};

import store from "@/store/index";
export const cart = {
    namespaced: true,
    state: initialState,
    actions: {
        getData({ commit }) {
            return CartService.getCart().then(
                cart => {
                    console.log("customer module get data")
                    commit('setCartData', cart);
                    return Promise.resolve(cart);
                },
                error => {
                    commit('cartDataFailure');
                    return Promise.reject(error);
                }
            );
        },
        setCartData({ commit }, cart) {
            commit('setCartData', cart);
        },
        setGuestCartId({ commit }, guestCartId) {
            commit('setGuestCartId', guestCartId);
        },
        unsetData({ commit }) {
            commit('unsetCartData');
        },
        createCart({ commit }) {
            commit('cartDataFailure');
        }
    },
    mutations: {
        setCartData(state, cart) {
            console.log("set cart data");
            state.cart = cart;
            console.log(state);
        },
        setGuestCartId(state, guestCartId) {
            console.log("set guest cart id data");
            state.guestCartId = guestCartId;
            console.log(state);
        },
        unsetCartData(state) {
            console.log("unset cart data");
            state.cart = null;
            state.guestCartId = null;
            localStorage.setItem('cart',null)
        },
        cartDataFailure(state) {
            CartService.createCart().then(cartId=>{
                console.log("cartId");
                console.log(cartId);
                state.guestCartId = cartId;
                store.dispatch("cart/getData");
            });
        },
    }
};