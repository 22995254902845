import axios from 'axios';
import apiConfig from "@/config/apiConfig";
const API_URL = apiConfig.getApiUrl();
import moment from 'moment';
const SIMPLE_PRODUCT_FILTER = "searchCriteria[filterGroups][1][filters][0][field]=type_id&searchCriteria[filterGroups][1][filters][0][conditionType]=eq&searchCriteria[filterGroups][1][filters][0][value]=simple";
const ACTIVE_PRODUCT_FILTER = "searchCriteria[filterGroups][1][filters][0][field]=status&searchCriteria[filterGroups][1][filters][0][conditionType]=eq&searchCriteria[filterGroups][1][filters][0][value]=1";
const ACTIVE_PRODUCT_FILTER_3 = "&searchCriteria[filterGroups][3][filters][0][field]=status&searchCriteria[filterGroups][3][filters][0][conditionType]=eq&searchCriteria[filterGroups][3][filters][0][value]=1";

class ProductService {

    getProductShippingGroupString($group_id){
        const $shipping_group_array = [];
        $shipping_group_array[2167] = "Paketversand";
        $shipping_group_array[2168] = "Stückgut";
        $shipping_group_array[2169] = "Lademeter bis 3m";
        $shipping_group_array[2170] = "Lademeter bis 6m";
        $shipping_group_array[2171] = "Lademeter bis 9m";
        $shipping_group_array[2264] = "Auf Anfrage";

        if(typeof $shipping_group_array[$group_id] !== 'undefined'){
            return $shipping_group_array[$group_id];
        } else {
            return null;
        }

    }
    getProductShippingGroupImgFileName($group_id){
       var $string =  this.getProductShippingGroupString($group_id);
       if($string.includes('Paketversand')) {
           return "Paketversand"
       } else if($string.includes("Lademeter bis 3m")){
           return "Lademeter3m"
       }else if($string.includes("Lademeter bis 6m")){
           return "Lademeter6m"
       }else if($string.includes("Lademeter bis 9m")){
           return "Lademeter6mplus"
       } else if($string.includes("Stückgut")){
           return "Stueckgut"
       }
       return false;

    }

    getProducts(store_code = "store_de",sku,store_id="1") {
        console.log(API_URL + store_code + '/V1/products?' + this.getPageSizeAndPageString() + this.getSKUFilterString(sku) + SIMPLE_PRODUCT_FILTER + this.getStoreIdFilterString(store_id) + ACTIVE_PRODUCT_FILTER_3);
        return axios.get(API_URL + store_code + '/V1/products?' + this.getPageSizeAndPageString() + this.getSKUFilterString(sku) + SIMPLE_PRODUCT_FILTER + this.getStoreIdFilterString(store_id) + ACTIVE_PRODUCT_FILTER_3);
    }
    findProducts(store_code = "store_de",search_string,store_id="1") {
        console.log(API_URL + store_code + '/V1/search?' + this.getSearchFilter(search_string) + '&' + SIMPLE_PRODUCT_FILTER + this.getStoreIdFilterString(store_id) + ACTIVE_PRODUCT_FILTER_3);
        return axios.get(API_URL + store_code + '/V1/search?'+this.getPageSizeAndPageString() + this.getSearchFilter(search_string) + '&' + SIMPLE_PRODUCT_FILTER + this.getStoreIdFilterString(store_id) + ACTIVE_PRODUCT_FILTER_3);
    }
    getProductById(store_code = "store_de", id) {
        console.log('id in upper function: ' + id);
        console.log(API_URL + store_code + '/V1/products/?' + this.getProductIdFilter(id));
        return axios.get(API_URL + store_code + '/V1/products/?' + this.getProductIdFilter(id));
    }
    getProduct(store_code = "store_de",sku) {
        console.log(API_URL + store_code + '/V1/products/' + sku + '?' + ACTIVE_PRODUCT_FILTER);
        return axios.get(API_URL + store_code + '/V1/products/' + sku);
    }
    getProductIdFilter(id) {
        console.log('id in function: ' + id);
        return "searchCriteria[filter_groups][0][filters][0][field]=entity_id&searchCriteria[filter_groups][0][filters][0][value]="+id+"&searchCriteria[filter_groups][0][filters][0][condition_type]=eq"
    }
    getSearchFilter(search_sring) {
        return "searchCriteria[requestName]=quick_search_container&searchCriteria[filterGroups][0][filters][0][field]=search_term&searchCriteria[filterGroups][0][filters][0][value]="+search_sring;
    }
    getSKUFilterString(sku) {
        return "searchCriteria[filterGroups][0][filters][0][field]=sku&searchCriteria[filterGroups][0][filters][0][condition_type]=eq&searchCriteria[filterGroups][0][filters][0][value]=" + sku + "&"
    }
    getPageSizeAndPageString(pageSize = 20, currentPage = 1){
        return "searchCriteria[pageSize]="+pageSize+"&searchCriteria[currentPage]="+currentPage+"&";
    }
    getStoreIdFilterString(store_id) {
        return "&searchCriteria[filterGroups][2][filters][0][field]=store_id&searchCriteria[filterGroups][2][filters][0][value]="+store_id
    }
    toCurrency(value) {
        value = parseFloat(value);
        if (typeof value !== "number") {
            console.log('no number');
            console.log(typeof value);
            return value;
        }
        var formatter = new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2
        });
        return formatter.format(value);
    }
    formatDate(value) {
        if (value) {
            return moment(String(value)).format('DD.MM.YYYY')
        }
    }
}
export default new ProductService();