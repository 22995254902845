<template>
  <div id="product-detail">
    <ResultHeader :sku="this.sku"/>
    <ProductDetailElement :product="product"></ProductDetailElement>
    <CemoContact></CemoContact>
  </div>
</template>

<script>
import ResultHeader from "@/components/partials/ResultHeader";
import ProductDetailElement from "@/components/partials/products/ProductDetailElement";
import ProductService from "@/services/product.service";
import CemoContact from "@/components/partials/CemoContact";

export default {
  name: "ProductDetail",
  components: { CemoContact, ProductDetailElement, ResultHeader},
  props: ["sku"],
  data() {
    return {
      product: false,
      isLoading: true
    }
  },
  created() {
    console.log("created");
    ProductService.getProducts(this.$t('config.store_code'), this.sku)
        .then(products => {
          console.log(products);
          if(products.data.items.length > 1){
            this.product = false;
          } else {
            this.product = products.data.items[0];
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        })
  }

}
</script>
