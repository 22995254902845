<template>
  <div class="header-cart-icon">
    <router-link to="/cart">
      <div class="header-cart-icon-inner">
        <font-awesome-icon icon="shopping-cart"></font-awesome-icon>
        <span class='v-badge' id='lblCartCount' v-if="cart.items_count > 0"> {{ cart.items_count }} </span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "HeaderCart",
  props: ["rerender"],
  data() {
    return {
      cart: {}
    }
  },
  methods: {
    getCart() {
      this.$store.dispatch("cart/getData").then(
          () => {
            this.cart = this.$store.state.cart.cart;
            console.log("cart in headercart");
            console.log(this.cart);
            console.log(this.rerender);
          },
          (error) => {
            this.cart.items_count = 0;
             this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    }
  },
  created() {
    this.getCart();
  },
  mounted() {
    this.emitter.on('changedCart', () => {
      console.log("change Cart in Header Cart");
      this.getCart();
    })
  }
}
</script>

