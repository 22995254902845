let loading = 0;
const initialState = {loading};
export const loader = {
    namespaced: true,
    state: initialState,
    actions: {
        setLoader({ commit }) {
            loading = loading+1;
            localStorage.setItem('loading', JSON.stringify(loading));
            commit('setLoader', loading);
        },
        unSetLoader({ commit }) {
            loading = loading-1;
            localStorage.setItem('loading', JSON.stringify(loading));
            commit('setLoader', loading);
        },
    },
    mutations: {
        setLoader(state, loading) {
            console.log("set loading");
            console.log(loading);
            state.loading = loading;
        },
    }
};